<script lang="ts" setup>
import { computed, useAttrs } from 'vue'

/**
 * UiCollapsible
 *
 * A base component for expanding / collapsing
 *
 * Built with help from Chat GPT 🤖
 */

const props = withDefaults(defineProps<{
  state: boolean
  horizontal?: boolean
  width?: number | string
  duration?: number | string
}>(), {
  state: true,
  duration: 0.3,
})

const emit = defineEmits([
  'update',
  'change',
])

const attrs = useAttrs()

const width = computed(() => {
  return props.width
    ? `${props.width}px`
    : ''
})

const style = computed(() => {
  const styles: Record<string, string> = {}
  if (props.horizontal && props.width) {
    styles.maxWidth = width.value || ''
  }
  styles.transition = `height ${props.duration}s ease, width ${props.duration}s ease`
  return styles
})

function beforeEnter(el: HTMLElement) {
  el.style.overflow = 'hidden'
  if (props.horizontal) {
    el.style.width = '0'
  }
  else {
    el.style.height = '0'
  }
  nextTick(() => {
    emit('update', true)
  })
}

function enter(el: HTMLElement) {
  el.style.overflow = 'hidden'

  if (props.horizontal) {
    el.style.width = 'auto'
    let targetWidth = el.offsetWidth

    // apply max width if provided
    if (props.width) {
      targetWidth = Math.min(targetWidth, Number(props.width))
    }

    el.style.width = '0'

    void el.offsetWidth // force repaint
    el.style.width = `${targetWidth}px`
  }
  else {
    el.style.height = `${el.scrollHeight}px`
  }
}

function afterEnter(el: HTMLElement) {
  el.style.width = ''
  el.style.height = ''
  el.style.overflow = ''
  emit('change', true)
}

function beforeLeave(el: HTMLElement) {
  el.style.overflow = 'hidden'
  if (props.horizontal) {
    el.style.width = `${el.offsetWidth}px`
  }
  else {
    el.style.height = `${el.scrollHeight}px`
  }
  nextTick(() => {
    emit('update', false)
  })
}

function leave(el: HTMLElement) {
  if (props.horizontal) {
    el.style.width = '0'
  }
  else {
    el.style.height = '0'
  }
}

function afterLeave(el: HTMLElement) {
  el.style.width = ''
  el.style.height = ''
  el.style.overflow = ''
  emit('change', false)
}
</script>

<template>
  <transition
    @before-enter="beforeEnter"
    @enter="enter"
    @after-enter="afterEnter"
    @before-leave="beforeLeave"
    @leave="leave"
    @after-leave="afterLeave"
  >
    <div
      v-show="state"
      data-ui="UiCollapsible"
      class="box-border overflow-hidden !p-0"
      :style="style"
    >
      <div :style="{ width }" v-bind="attrs">
        <slot />
      </div>
    </div>
  </transition>
</template>
